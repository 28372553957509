import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {

  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { ListTLink, CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';
import MagneticButton from '../../components/MagneticButton';

import Icon from '../../components/Icon';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
      <SEO
  title="Graafiline disain ja reklaami kujundamine"
  description="Vajad graafilist disaini? Ära piirdu keskpärasusega ja vaata, mida pakume. Töö kiire ja kvaliteetne."
  image="/pagecover-teenused.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Graafiline disain ja reklaami kujundamine",
        "provider": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Graafiline disain, trükimeedia ja reklaamide kujundus",
        "description": "Pakume kvaliteetset graafilist disaini, sealhulgas trüki- ja digireklaamide kujundust, brändiidentiteeti ning animatsioone.",
        "url": "https://give.ee/teenused/graafiline-disain/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digiagentuur",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Graafilise disaini portfoolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "about": "Disainime kvaliteetseid reklaame ja trükiseid, mis aitavad ettevõtetel oma brändi esile tõsta.",
        "url": "https://give.ee/teenused/graafiline-disain/",
        "image": "https://give.ee/pagecover-teenused.jpg"
      }
    ]
  }}
/>

      </GiveContentTrans>
      <GiveContentTrans language="en">
      <SEO
  title="Graphic design and advertising campaigns"
  description="Do you need help with graphic design? We create stunning print and digital materials that turn heads and drive results."
  image="/pagecover-en-default.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Graphic Design and Advertising",
        "provider": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Graphic design, branding, and digital advertising",
        "description": "We provide high-quality graphic design services, including print and digital advertising, branding, and animations.",
        "url": "https://give.ee/en/services/graphic-design/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digital Agency",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Graphic Design Portfolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "about": "We create high-quality graphic designs and advertising materials that help businesses enhance their brand presence.",
        "url": "https://give.ee/en/services/graphic-design/",
        "image": "https://give.ee/pagecover-en-default.jpg"
      }
    ]
  }}
/>

      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
        <h1>Graafiline disain on sinu vaikne müügimees</h1> 

        </GiveContentTrans>
        <GiveContentTrans language="en">
        <h1>Graphic design is your silent salesman</h1>

        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
            Disain ei ole lihtsalt ilu asi. See on strateegia.
Graafiline disain muudab vaataja kliendiks – digis ja trükis.
Aitame sind sellega. Kiiresti ja kvaliteetselt. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
            Design isn’t just about looks. It’s a strategy.  
Smart graphic design turns viewers into customers — in print and online.  
We help you do it fast and with high quality.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/graafilinedisain1.jpg"
            alt="Graafiline disain Ekspressi esikaanele"
            title="Graafiline disain ajalehereklaamile"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/graafilinedisain1.jpg"
            alt="Graphic design for Eesti Ekspress cover"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Graafiline disain on investeering</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Graphic design is an investment</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
                                <p>
                                Mõned reklaamid lihtsalt jäävad silma.
                  See pole juhus. See on hea graafiline disain.
                  Meie töö disainiagentuuris on on muuta su sõnum nähtavaks ja meeldejäävaks.
                                </p>
                                <p>
                          
                                Aitab sul luua visuaalseid lahendusi, mis töötavad nii trükis ajalehtede esikülgedel, digis suurtel ja väikestel ekraanidel, tänavatel või sotsiaalmeedias.
                                  </p>
                                <p>
                                Meil on kogemus animeeritud ekraanilahendustega ja loome graafilist disaini, mis ei jää märkamatuks. Give ei ole lihtsalt graafilise disaini teenusepakkuja. Me teeme kujundused, mis toovad sulle uusi kliente.

                  </p>
                              </GiveContentTrans>
                              <GiveContentTrans language="en">
                              <p>
                  Some ads just get noticed. That’s no accident. That’s good graphic design.  
                  At our design agency, we turn your message into something people notice.
                  </p>
                  <p>
                  We craft visuals that work across print and digital — on newspaper front pages, massive digital screens, city streets, or social media feeds.
                  </p>
                  <p>
                  We have experience with animated screen ads and design visuals that don’t go unnoticed.  
                  Give isn’t just another graphic design provider.  
                  We create designs that bring you customers.
                  </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafilinedisain2.jpg"
                alt="Graafiline disain lauarääkijale"
                title="Graafiline disain trükistele"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafilinedisain2.jpg"
                alt="Print advertisment"
                title="Graphic Design for print"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">

        <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafilinedisain3.jpg"
                alt="Visiitkaardi graafiline disain"
                title="Graafiline disain"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafilinedisain3.jpg"
                alt="Graphic design for business cards"
                title="Graphic design for business card"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>


          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>
                Graafilise disaini teenused
              </h3>
              <p>
Pakume laia valikut graafilise disaini teenuseid, mis katavad nii trüki- kui digivajadused. Alljärgnevalt leiad ülevaate töödest, mida oleme klientidele loonud või mille tellimiseks meie poole pöördutakse.
</p>
          <ul>
          <li>OOH reklaami disain</li>
  <li>Reklaamplakatite disain</li>
  <li>Voldikute, brošüüride jm. trükiste kujundamine</li>
  <li>Visiitkaartide kujundamine</li>
  <li>Brändimaterjalide kujundamine (<ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             logo kujundamine
            </ListTLink>, visuaalne identiteet jne)</li>
  <li>Pakendikujundus</li>
  <li>Infograafika disain</li>
  <li>Digitaalse reklaami disain</li>
  <li>Sotsiaalmeedia graafika kujundus</li>
  <li>Messibokside kujundus</li>
  <li>Presentatsioonide ja "pitch deckide" disain</li>
  <li>Töökuulutuse disain</li>
  <li><ListTLink
              to="/teenused/html5bannerid/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             Bänneri kujundus
            </ListTLink></li>
  <li>Illustratsioonid</li>
  <li>Autokleebiste graafiline disain</li>
  <li>E-kaartide, kutsete ja diplomite disain</li>

</ul>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>
  Graphic Design Services
</h3>
                    <p>
                    We offer a wide range of graphic design services that cover both print and digital needs. Below is an overview of the most common types of design work we create for our clients — from outdoor ads to pitch decks and everything in between.
                    </p>
                    <ul>
                      <li>Graphic Design of advertising posters</li>
                      <li>Design of leaflets, brochures, and other printed materials</li>
                      <li>Design of business cards</li>
                      <li>Brand material design (<ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             logo design
            </ListTLink>, visual identity, etc.)</li>
                  <li>Packaging design</li>
                  <li>Creation of infographics</li>
                  <li>Design of digital advertising</li>
                  <li>Social media graphics</li>
                  <li>Design of exhibition booths</li>
                  <li>Designing presentations and pitch decks</li>
                  <li><ListTLink
                              to="/services/html5banners/"
                              exit={{
                                length: 1,
                                zIndex: 2, // exit default is 0
                                trigger: ({ exit, e, node }) => animateObjects(exit, node),
                              }}
                              entry={{
                                delay: 0.5,
                                length: 0.6,
                                trigger: ({ entry, node }) => newContent(node),
                              }}
                            >
                            Web banner design
                            </ListTLink></li>
                  <li>Creation of illustrations</li>
                  <li>Graphic Design of car stickers</li>
                  <li>Graphic Design of e-cards, invitations, and diplomas</li>
                </ul>
            </GiveContentTrans>
          </div>
  
        </div>



        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Graafilise disaini tööd</h2>
          </div>

        <div className="content-row has-media">

        <div className="gd-grid">

            <div className="gd-grid-item">
  
            <StaticImage
                  src="../../assets/Graafiline-disain-digiekraanile.jpg"
                  alt="Graafiline disain digiekraanil"
                  layout="fullWidth"
                  title="Graafiline disain digiekraanil"
                />
            </div>

            <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/graafiline-disain-kott.jpg"
        alt="Kandekotti graafiline disain"
        layout="fullWidth"
        title="Kandekotti graafiline disain"
      />
  </div>

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile2.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-OOH.jpg"
        alt="Graafiline disain OOH reklaamile"
        layout="fullWidth"
        title="OOH reklaami disain"
      />
  </div>

 

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-OOH2.jpg"
        alt="Graafiline disain OOH reklaamile"
        layout="fullWidth"
        title="OOH reklaami kujundus"
      />
  </div>


  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile3.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>


        
  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Menuu-graafiline-disain.jpg"
        alt="Restorani menüü graafiline disain"
        layout="fullWidth"
        title="Menüü graafiline disain"
      />
  </div>


  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile4.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>


  

          </div>
       </div>
       </section>
       </GiveContentTrans>



 
        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
            <h2>Miks valida Give graafilise disaini partneriks?</h2>
                                <p>
                                Disainiagentuure on palju, aga Give puhul on üks oluline erinevus. Me ei keskendu lihtsalt ilusale. Me keskendume sellele, mis töötab. Meie disainer on aidanud kujundada üle 1000 erineva projekti – alates klassikalistest trükistest kuni animatsioonidega OOH reklaamideni.  </p> 
                                <p>Iga töö puhul mõtleme läbi, miks see disain eksisteerib ja kuidas see aitab sinu äri eesmärke täita. Kui sa tahad partnerit, kes disainib kaasa mõeldes, siis oled õiges kohas.

                  </p>
                              </GiveContentTrans>
                              <GiveContentTrans language="en">
                              <h2>Why choose Give as your graphic design partner?</h2>
<p>
There are plenty of design agencies out there. But at Give, we’re not just about aesthetics—we’re about impact. As a professional graphic design agency in Estonia, we’ve delivered over 1,000 successful projects, from classic print ads to full-screen animated OOH campaigns across Tallinn.
</p>
<p>
Every piece we create is built to drive results—visibility, trust, and sales. If you're looking for high-converting marketing materials, creative visual identity, or just a design partner who thinks with you, you're in the right place. Strategic design is what we do best.
</p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">

            <h2>Kuidas graafiline disain mõjutab sinu äri tulemusi?</h2>
            <p>
            Hea disain ei küsi tähelepanu. Ta saab selle. Silmapaistev graafiline disain jääb meelde, tekitab usaldust ja muudab vaataja ostjaks. Just sellepärast ei tasu rahulduda keskpärase lahendusega. Kui su reklaamid ei tööta, pole alati süüdi toode. Sageli on puudu lihtsalt selge ja läbimõeldud visuaalne sõnum.     </p>
                  <p>Graafiline disain ei ole kulu – see on investeering, mis hakkab sulle raha tagasi teenima juba siis, kui keegi tänaval su plakati peale peatub.</p> 
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h2>How does graphic design impact your business results?</h2>
<p>
Good design doesn’t ask for attention—it earns it. Eye-catching graphic design builds trust, sticks in people’s minds, and turns viewers into buyers. If your ads aren’t performing, it’s not always the product to blame. Often, what’s missing is a clear and strategic visual message.
</p>
<p>
Graphic design isn’t an expense—it’s an investment. One that starts paying back the moment someone stops in the street to look at your poster.
</p>
            </GiveContentTrans>
          </div>
        </div>



        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>Graafilise disaini kohta</h2>

          <p>Kui sul on küsimusi ära pelga. Kirjuta <a href="mailto:studio@give.ee">studio@give.ee</a> ja saad kiired vastused. </p>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Mis kogemus on Gives graafilise disainiga?</h3>
      <div className="answer">
      <p>Give on spetsialiseerunud kasutajasõbralike ja tipptasemel <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >UI/UX</ListTLink> disaini ja <ListTLink
            to="/teenused/kodulehed/"
            exit={{
              length: 1,
              zIndex: 2, // exit default is 0
              trigger: ({ exit, e, node }) => animateObjects(exit, node),
            }}
            entry={{
              delay: 0.5,
              length: 0.6,
              trigger: ({ entry, node }) => newContent(node),
            }}
          >kodulehtede</ListTLink> valmistamisele. Paljusid kliente aitame ka disaini terviklahendusega. Meie disainer on ligi 20 aasta jooksul kujundanud 
        erinevaid brände ja kõikvõimalikku graafikat.</p>

      <p>Kui me ise midagi teha ei oska või leiame, et on mõistlik kasutame sobivadi eriala spetsialiste (nt. illustraatorit, 3D disainerit, desktopperit). </p>
      <p>Statistiliselt on Give disaineril kogemust üle 1000 erineva graafilise disaini, brändi või UI/UX disaini projektiga.</p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kui palju maksab graafiline disain ja millest hind sõltub?</h3>
      <div className="answer">
      <p>Hind sõltub töö mahust, mitte sellest, kui ilus kujundus välja kukub.</p>
      <p>Meie töö puhul tead alati, mille eest maksad. Soovi korral teeme sulle kiire ja konkreetse hinnapakkumise.


        </p>
        <p>Pakume ka fikseeritud kuumaksuga (3507€+km) piiramatut disaini tervikteenust. Lisaks on paketis ka Framer/Webflow kodulehe valmistamise, halduse ja ui/ux disaini teenused. 
          Nii saate tellida piiramatult kujundustöid ja ei pea muretsema iga üksiku projekti hinnastamise üle. Enamik ülesandeid va. suuremahulised tööd valmivad tööpäevadel 48 tunni jooksul. Selle teenusega võite säästa aastas kümmneid tuhandeid eurosid. 
        </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kas saan muudatusi küsida, kui disain ei meeldi?</h3>
      <div className="answer">
      <p>Jah, ikka teeme!</p>
      <p>Meile on tähtis, et tunneksid uhkust selle disaini üle mida meilt saad. Omalt poolt alati selgitame miks mingisugused disainialased otsused langetasime ja kuidas meie graafilise disaineri mõtte töötas.
        </p>
      <p>
        Graafilises disainis ei saa alati eristada õiget ja valet. Ei ole olemas ühte lahendust mis päris kindlasti töötab. Loovtöö protsessi osa on proovida erinevaid lahendusi kuni selgub see õige. 
        Disaini projektides proovime tavaliselt läbi mitu erinevat lahendust enne kui jõuame ideeni mis on õige ja sobib teile esitlemiseks. 
         </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mida tähendab prepress ehk trükiettevalmistus</h3>
      <div className="answer">
      <p>Prepress on graafilise disaini protsessi osa, mis hõlmab ettevalmistustöid trükimeedia tootmiseks. See etapp on oluline, kuna tagab trükifailide kvaliteedi ja sobivuse trükimasinatele. Prepress sisaldab selliseid tegevusi nagu värvihaldus, kujunduse optimeerimine trükiks, failiformaatide kohandamine trükimasinatele ja värvi eraldamine erinevate trükikomponentide jaoks. Selle eesmärk on tagada trükimeedia, nagu ajakirjad, brošüürid, plakatid jms, kvaliteetne ja veatu tootmine.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Kuidas luua ühtne visuaalne brändi keel erinevates kanalites?</h3>
      <div className="answer">
      
      <p>Ühtse visuaalse keele kasutamine on iga brändi jaoks oluline. See aitab teie ettevõttel silma paista ning meelde jääda. Selle vundamendiks võiks olla tugev 
      brändiraamat. Mis see on saad lugeda <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             firmastiili ja logode kujundamise teenuse lehelt
            </ListTLink>.
      </p>

      <p>Kasutage sarnaseid värvitoone, fonte ja graafikat erinevates materjalides, et säilitada brändi ühtsus.</p>

      <p>Kõige parem on aga kasutada võimalikult paljude tööde jaoks ühte agentuuri. Ja soovitatavalt väikest. Nii on kõikidele osapooltele kõik asjad alati teada ja saab hoida kokku aega.</p>

      </div>
    </li>

 

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miliseid graafilise disaini programme kasutate?</h3>
      <div className="answer">
      <p>Kasutame Adobe Creative Cloudis olevat kujundustarkvara. Digitaalse UI/UX disiani puhul lisaks Figmat või Sketci.</p>
      <p>Kliendina ei peaks te muretsema, et midagi jääb programmide taha. Omame kogemust Adobe Photoshopi, Illustraatori, AfterEffectsi, InDesigni ja teiste programmidega. 3D programmidest kasutame vabavaralist Blenderit ja Spline.</p>
      <p>Kui soovite meile edastada tööfaile oleks hea kui need kuuluvad Adobe tooteperekonda. Aga alati tasub üle küsida ja usun, et leiame lahendused ka muudele erijuhtudele.</p>
      </div>
    </li>



    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kui kiirelt te kujundused valmis saate?</h3>
      <div className="answer">
      <p>Tavaliselt kiiremini kui ükski teine agentuur. Väiksemad tööd saavad valmis paari päevaga. Suuremad lahendused planeerime koos. 
      </p>
      <p>
        Give on väike agentuur ja meie töömaht sõltub päevast. Mõnikord on jube kiire erinevate väiksemate tööde kujundamisega ja teinekord on rohkem vaba aega või tegeleme pikemate projektidega ning saame 
        pisemaid graafilise disaini projekte nende vahele paigutada. Iga graafilise disaini projekti puhul peab arvestama, et töö valmimise aeg sõltub kliendilt saadud tagasisidest ja selle kiirusest. 
      </p>
      <p>Me ei vea alt. Kui lubame, et asi saab tehtud, siis see saab tehtud.</p>
     
      </div>
    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>




        <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Graafilise disaini tellimine on super lihtne</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Ordering graphic design work is super easy</h2>
        </GiveContentTrans>

        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
              Graafilise disaini tellimine ei pea olema keeruline ega kallis. Hea disain on investeering, mis hakkab end tasuma esimesest silmapilgust. Läbimõeldud visuaal aitab su sõnumil kohale jõuda ja muudab selle meeldejäävaks – nii digis kui trükis.
              </p>
              <h3>Soovid oma brändi graafilise disaini viia uuele tasemele?</h3>
              <p>
              <ListTLink
              to="/kontakt/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
            Kirjuta meile
            </ListTLink>, või broneeri tasuta kõne – arutame su vajadused läbi ja pakume välja parima suuna.

              </p>
              <a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Broneeri tasuta videokõne"
      magneticDistance="150"
      magneticSpeed="0.4"
    /> 
  </a>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
              Getting great graphic design shouldn’t be complicated or expensive. Smart, well-crafted design is an investment that starts paying off from the first impression. Whether it’s digital or print, we help your message stand out and stick.
              </p><p>
              Ready to take your brand to the next level?
Send us a quick brief of what you need first—or book a free call and let’s figure it out together.
              </p>

              <a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Book a Call"
      magneticDistance="150"
      magneticSpeed="0.4"
    /> 
  </a>
            </GiveContentTrans>
          </div>
        </div>


        <div className="media">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafiline-disain-plakat.jpg"
                alt="Graafiline disain plakat"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafiline-disain-plakat.jpg"
                alt="Graphic Design Poster"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>



      </section>

        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Logo ja brändiraamatu kujundamine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Brandbook creation
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>
      </main>
      </Layout>
  
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;